html {
background-color: #F2F2F2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Cards ScrollBar Custom */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #bbbbbb;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.282);
}

/* Table Footer Unnecesary data */
.MuiTablePagination-selectLabel{
  display: none!important;
}

.MuiTablePagination-displayedRows {
  display: none!important;
}

.MuiTablePagination-actions {
  display: none!important;
}

.MuiTablePagination-toolbar {
  padding-left: 0!important;
}

.MuiTablePagination-select {
  background-color: #F1F5F9!important;
  border-radius: 10px!important;
  width: 100%!important;
  height: 100%!important;
  font-weight: bold!important;
}